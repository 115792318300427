<template>
  <div class="public Intelligent">
    <el-container>
      <!-- <el-aside width="220px" class="left-box">
        <el-menu :default-openeds="['1']">
          <el-menu-item index="1">
            <span slot="title">智能分析</span>
          </el-menu-item>
        </el-menu>
      </el-aside> -->
      <el-container>
        <el-main>
          <el-form :inline="true" :model="formInline" class="form-inline">
            <el-row>
              <el-form-item >
                <label>地区：</label>
              <el-select v-model="formInline.pid" placeholder="请选择"  @change="changeProv">
                <el-option label="全国" value="" :disabled="initCitys.provinceId ? true : false"></el-option>
                <el-option v-for="item in provinceOptions"
                           :key="item.regionId"
                           :label="item.regionName"
                           :value="item.regionId"
                           :disabled="initCitys.provinceId !== '' && initCitys.provinceId != item.regionId"
                ></el-option>
              </el-select>
              <el-select v-model="formInline.cid" placeholder="请选择" @change="changeCity">
                <el-option label="全部" value="" :disabled="initCitys.cityId ? true : false"></el-option>
                <el-option v-for="item in cityOptions"
                           :key="item.regionId"
                           :label="item.regionName"
                           :value="item.regionId"
                           :disabled="initCitys.cityId !== '' && initCitys.cityId != item.regionId"
                ></el-option>
              </el-select>
              <el-select v-model="formInline.did" placeholder="请选择" @change="changeArea">
                <el-option label="全部" value="" :disabled="initCitys.areaId ? true : false"></el-option>
                <el-option v-for="item in areaOptions"
                           :key="item.regionId"
                           :label="item.regionName"
                           :value="item.regionId"
                           :disabled="initCitys.areaId !== '' && initCitys.areaId != item.regionId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <label>经营者类型：</label>
              <el-select v-model="formInline.hairpinType" placeholder="公司性质">
                <el-option label="全部" value=""></el-option>
                <el-option v-for="item in cardTypeOptions" :key="item.haripinType" :label="item.name" :value="item.haripinType"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <label>领域行业：</label>
              <el-select v-model="formInline.industry" placeholder="领域" @change="changeIndustry">
                <el-option label="全部领域" value=""></el-option>
                <el-option v-for="item in industryOptions" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
              <el-select v-model="formInline.industryId" placeholder="行业">
                <el-option label="全部行业" value=""></el-option>
                <el-option v-for="item in subIndustryOptions" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            </el-row>
            <el-row>
            <el-form-item>
              <el-input placeholder="请输入经营者名称" v-model="formInline.content"  class="search-box">
                <el-button slot="append" icon="el-icon-search" @click="tableSearch">搜索</el-button>
              </el-input>
            </el-form-item>
            </el-row>
          </el-form>
          <div class="default-table">
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="busName" label="经营者名称" width="300"> </el-table-column>
              <el-table-column prop="safeCodeImg" label="预付码" :width="0.18 * $bodyWidth">
                <template slot-scope="scope">
                  <img :src="baseUrl+scope.row.safeCodeImg" style="width:60px;height:60px;"/>
                </template>
              </el-table-column>
              <el-table-column prop="location" label="所属地区"> </el-table-column>
              <el-table-column prop="industry" label="所属行业"> </el-table-column>
              <el-table-column prop="fundMode" label="监管方式"> </el-table-column>
              <el-table-column prop="hairpinType" label="经营者类型"> </el-table-column>
              <el-table-column label="查看" :width="0.18 * $bodyWidth">
                <template slot-scope="scope">
                  <el-button type="text" @click="goPage(scope.row.riskBusId)">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="page">
              <el-pagination background layout="total, prev, pager, next" prev-text="上一页" next-text="下一页"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script type="text/javascript">
import {baseURL} from "@/api/config"
import { cityList, cardType } from "@/api/common";
import { industry, search } from "@/api/intelligent";

export default {
  name: "Intelligent",
  data() {
    return {
      initCitys: {
        provinceId:'',
        cityId:'',
        areaId:'',
      },
      labels:{
        pLabel:"",
        cLabel:"",
        aLabel:"",
        pid:'',
        cid:'',
        did:'',
      },
      provinceOptions:[],
      cityOptions:[],
      areaOptions:[],
      industryOptions:[],
      subIndustryOptions:[],
      cardTypeOptions:[],
      formInline:{
        pid:'',
        cid:'',
        did:'',
        hairpinType:'',
        industry:'',
        industryId:'',
        content:''
      },
      total:0,
      pageSize:10,
      currentPage:1,
      tableData:[],
      baseUrl:''
    };
  },
  mounted() {
    this.baseUrl = baseURL
    // this.intData();
  },
  watch:{
    $route: {
      handler(val, oldVal) {
        console.log("val=====",val)
        if(val.query && val.query.provinceId){
          this.initCitys = val.query
          if(this.initCitys.provinceId || this.initCitys.cityId || this.initCitys.areaId){
            this.intData(true);
          }
        }else{
          this.intData();
        }
      },
      deep: true, //增加deep 观察对象的子对象变化
      immediate:true,
    },
  },

  methods: {
    intData(initCity) {
      this.cityList()
      this.industryList()
      this.cardType()
      if(!initCity){
        this.tableSearch()
      }

    },
    cityList(){
      cityList({
        regionId:1
      }).then((res) => {
        if(this.initCitys.provinceId){
          let isHasId = false;
          res.data.map((val)=>{
            if(this.initCitys.provinceId == val.regionId){
              isHasId = true;
              this.labels.pLabel = val.regionName;
            }
          })
          if(isHasId){
            this.formInline.pid = this.initCitys.provinceId - 0
          } else{
            this.initCitys.provinceId = ''
          }
          this.changeProv(this.formInline.pid)

          if(!this.initCitys.cityId){
            console.log("pid",this.formInline.pid)
            this.$nextTick(()=>{
              this.tableSearch()
            })

          }
        }
        this.provinceOptions = res.data;
      })
    },
    changeProv(val){
      //console.log(val)
      if(val==""){
        this.formInline.cid = ''
        this.cityOptions = []
        this.formInline.did = ''
        this.areaOptions = []
      }else{
        this.formInline.cid = ''
        this.cityOptions = []
        this.formInline.did = ''
        this.areaOptions = []
        cityList({
            regionId:val
          }).then((res) => {
          if(this.initCitys.cityId){
            let isHasId = false;
            res.data.map((val)=>{
              if(this.initCitys.cityId == val.regionId){
                isHasId = true;
                this.labels.cLabel = val.regionName;
              }
            })
            if(isHasId){
              this.formInline.cid = this.initCitys.cityId - 0;
            } else{
              this.initCitys.cityId = '';
            }
            this.changeCity(this.formInline.cid)
            console.log("cid",this.formInline.did)
            if(!this.initCitys.areaId){
              this.$nextTick(()=>{
                this.tableSearch()
              })
            }
          }
            this.cityOptions = res.data;
          })
      }
    },
    changeCity(val){
      if(val==""){
        this.formInline.did = ''
        this.areaOptions = []
      }else{
        this.formInline.did = ''
        this.areaOptions = []
        cityList({
          regionId:val
        }).then((res) => {
          if(this.initCitys.areaId){
            let isHasId = false;
            res.data.map((val)=>{
              if(this.initCitys.areaId == val.regionId){
                isHasId = true;
                this.labels.dLabel = val.regionName;
              }
            })
            if(isHasId) {
              this.formInline.did = this.initCitys.areaId - 0
            }else{
              this.initCitys.areaId = ''
            }
            this.tableSearch()
          }
          this.areaOptions = res.data;
        })
      }
    },
    changeArea(val){
      if(val==""){
        this.formInline.did = ''
      }
    },
    industryList(){
      industry().then((res) => {
        this.industryOptions = res.data;
      })
    },
    changeIndustry(val){
      this.formInline.industryId = ''
      this.subIndustryOptions = []
      if(val!=""){
        industry({
          industryId:val
        }).then((res) => {
          this.subIndustryOptions = res.data;
        })
      }
    },
    cardType(){
      cardType().then(res=>{
        this.cardTypeOptions = res.data
      })
    },
    tableSearch(){
      this.tableData = [];
      let params = {
        pid:this.formInline.pid==""?undefined:this.formInline.pid,
        cid:this.formInline.cid==""?undefined:this.formInline.cid,
        did:this.formInline.did==""?undefined:this.formInline.did,
        hairpinType:this.formInline.hairpinType==""?undefined:this.formInline.hairpinType,
        industryId:this.formInline.industryId!==""?this.formInline.industryId:this.formInline.industry==""?undefined:this.formInline.industry,
        content:this.formInline.content==""?undefined:this.formInline.content,
      }
      search({
        limit:this.pageSize,
        page:this.currentPage,
        ...params
      }).then((res) => {
        this.tableData = res.data.list || []
        this.total = res.data.totalCount
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.tableSearch()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.tableSearch()
    },
    goPage(val){
      // console.log(this.labels)
      window.sessionStorage.setItem("labels",JSON.stringify(this.labels))
      this.$router.push({path:'/IntelligentAnalysis',query:{riskBusId:val}})
    }
  },
  components: {},
};
</script>

<style lang="scss" scoped >
.Intelligent {
  .left-box {
    width: 220px;
    background: url("../assets/imgs/insight-bg.jpg") no-repeat;
    padding-top: 10px;
  }
  .form-inline{
    margin-top:20px;
    .el-select{
        width:120px;
        margin-right:15px;
    }
    .search-box{
      width: 800px;
      box-shadow: 0 0 30px rgba(67, 209, 255, 0.2);
      border-radius:50px;
    }
    label{
      font-size:12px;
    }
  }
}
</style>
